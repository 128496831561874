import React from "react";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { PostJobFormProvider } from './src/components/utils/PostJobFormContext';

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  let run_ga_code = false;

  if (typeof document !== "undefined") {
    if (document.cookie.indexOf('cookie_choices') < 0) {
      run_ga_code = true;
    }

    if (document.cookie.indexOf('cookie_choices') >= 0) {
      const parts = document.cookie.split(`;`);

      parts.forEach((p) => {
        if (p.indexOf('cookie_choices') >= 0) {
          const decoded = decodeURIComponent(p);
          const [, val] = decoded.split('cookie_choices=');
          const parsed = JSON.parse(val);

          if(parsed.analytics) {
            run_ga_code = true;
          }
        }
      });
    }
  }

  if (!run_ga_code) {
    return true;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  const pageUrl = "https://www.climatechangecareers.com" + pagePath;
  
  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_location: pageUrl });
    }
  }, 100);

  return true;
};

export const wrapRootElement = ({ element }) => {
  return <PostJobFormProvider>{element}</PostJobFormProvider>;
};
