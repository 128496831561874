import React, { createContext, useState } from 'react';

const PostJobFormContext = createContext({
  isPostJobFormDirty: false,
  setPostJobFormDirty: () => {},
});

export const PostJobFormProvider = ({ children }) => {
  const [isPostJobFormDirty, setPostJobFormDirty] = useState(false);

  return (
    <PostJobFormContext.Provider value={{ isPostJobFormDirty, setPostJobFormDirty }}>
      {children}
    </PostJobFormContext.Provider>
  );
};

export default PostJobFormContext;